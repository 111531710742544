import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { APIClient, APIUtils } from '@agerpoint/api';

interface IuseViewerControllerSharedQueries {
  captureJob?: APIClient.CaptureJob;
}

export const useViewerControllerSharedQueries = ({
  captureJob,
}: IuseViewerControllerSharedQueries) => {
  const queryClient = useQueryClient();

  const { captureId } = useParams();
  const captureJobPutMutation = APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: async () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJob?.id) },
          ],
        });
      },
      // onSuccess: (_, variables) => {
      //   APIUtils.updateQueryCache({
      //     data: variables.data,
      //     queryClient,
      //     queryKey: [
      //       APIUtils.QueryKey.captures,
      //       { captureId: Number(captureId) },
      //       APIUtils.QueryKey.captureJobs,
      //       { captureJobId: Number(variables.jobId) },
      //     ],
      //   });
      // },
    },
  });

  return { captureJobPutMutation };
};
