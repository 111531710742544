import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { APIClient, APIUtils } from '@agerpoint/api';

export const useSharedToolbarQueries = ({
  captureJob,
}: {
  captureJob?: APIClient.CaptureJob | undefined | null;
}) => {
  const queryClient = useQueryClient();
  const { captureId } = useParams();

  const captureJobPutMutation = APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: async () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJob?.id) },
          ],
        });
      },
    },
  });

  return {
    captureJobPutMutation,
  };
};
