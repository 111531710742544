import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Raycaster, Vector2, Vector3 } from 'three';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { Viewer } from '@agerpoint/three-d-viewer';
import {
  Annotation3dPoints,
  BackgroundOptionsValues,
  CaptureMaterialClassifications,
  CaptureMaterialClassificationsLabels,
  CaptureMaterialColorsLookup,
  CaptureObjectMarkerType,
  EventBusNames,
  FrameEvent,
  ICustomMesh,
  IPotreeViewerController,
  LdFlags,
  MarkerObjOptions,
  MaterialType,
  MeasurementOptionsValues,
  PotreeViewer2AddObjectProps,
  PotreeViewer2ControllerInfoClassificationProps,
  PotreeViewer2InfoModalProps,
  PotreeViewer2Props,
  PotreeViewer2ZoomToLocationProps,
} from '@agerpoint/types';
import {
  Potree,
  createFilename,
  createPyramid,
  environment,
  eventBus,
  hasPermission,
  markerColorPalette,
  useGlobalStore,
} from '@agerpoint/utilities';

import { SharedThreeDAnnotationToolbar } from '../annotations/3d/shared-toolbar/anno-shared-toolbar';
import { useCapturesViewerContext } from '../captures-viewer';
import { SharedCroppingToolbar } from '../crop-3d';
import {
  useDestroyCroppingTool,
  useFinishCropEditingTool,
  usePauseOrbitControls,
  useResetCropBox,
  useResetCropEditingTool,
  useResumeOrbitControls,
  useStartCropEditingTool,
  useToggleCropEditingTool,
} from '../viewer-controller-shared/viewer-controller-shared';
import { PotreeCloudTools } from './subcomponents/potree-cloud-tools';
import { PotreeTools } from './subcomponents/potree-tools';

export const PotreeViewerController = ({
  cloudApp = false,
  controller: setController,
  showTools = false,
  showCloudTools = false,
  showShareTools = false,
  plugins,
  token,
}: PotreeViewer2Props) => {
  const {
    setAnnotations3dGeometry,
    cropBoxEditingActive,
    setCropBoxEditingActive,
    selectedCaptureJob,
  } = useCapturesViewerContext();
  const { permissions } = useGlobalStore();
  const { captureId } = useParams();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      enabled: Number.isSafeInteger(Number(captureId)),
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
    },
  });
  const [viewerReady, setViewerReady] = useState(false);
  const [viewerInitialized, setViewerInitialized] = useState(false);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [pointStyle, setPointStyle] = useState<MaterialType>(MaterialType.RGBA);
  const [captureMetadata, setCaptureMetadata] = useState<APIModels.Capture>();
  const [captureJobMetadata, setCaptureJobMetadata] = useState<
    APIModels.CaptureJob | undefined
  >();
  const [cameraPositionsVisible, setCameraPositionsVisible] = useState(false);
  const [classificationClasses, setClassificationClasses] =
    useState<PotreeViewer2ControllerInfoClassificationProps[]>();
  const [croppingFrame, setCroppingFrame] = useState<FrameEvent | null>(null);
  const potreeRenderAreaRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<{
    threeViewer: Viewer;
  }>();

  const [measurementsVisible, setMeasurementsVisible] = useState(true);
  const [viewerError, setViewerError] = useState<Error>();
  const mousePosition = useRef<Vector3 | undefined>();

  const [eptJson, setEptJson] = useState<{
    pointcloud: {
      pcoGeometry: {
        schema: {
          name: string;
          counts: {
            value: number;
          }[];
        }[];
      };
    };
  }>();

  const objectCount = useRef(0);

  const objectOnClickRef = useRef<{
    eventId: string;
    callback: (e: CustomEvent) => void;
  }>();

  const cameraPositionOnClickRef = useRef<{
    eventId: string;
    callback: (e: CustomEvent) => void;
  }>();

  const hasDebugPermission = useMemo(
    () => hasPermission(LdFlags.Debug3dFeatures, permissions),
    [permissions]
  );

  useEffect(() => {
    if (sceneLoaded && viewerReady) {
      setCameraSettings();
    }
  }, [viewerReady, sceneLoaded]);

  useEffect(() => {
    if (!selectedCaptureJob) {
      setCaptureJobMetadata(undefined);
      return;
    }
    setCaptureJobMetadata(selectedCaptureJob);
  }, [selectedCaptureJob]);

  useEffect(() => {
    if (cameraPositionsVisible) {
      eventBus.dispatch(EventBusNames.CaptureCameraPositionShowAllClicked, {
        detail: undefined,
      });
    } else {
      eventBus.dispatch(EventBusNames.CaptureCameraPositionHideAllClicked, {
        detail: undefined,
      });
    }
  }, [cameraPositionsVisible]);

  useEffect(() => {
    const schema = eptJson?.pointcloud?.pcoGeometry?.schema;
    if (!schema) return;
    const classification = schema.find(
      (s: { name: string }) => s.name === 'Classification'
    );
    setClassificationClasses(undefined);

    if (
      !classification ||
      !classification?.counts ||
      classification?.counts.length === 0
    ) {
      setPointStyle(MaterialType.RGBA);
      viewerRef?.current?.threeViewer?.classification.switchPointCloudMaterial(
        MaterialType.RGBA
      );
      return;
    }
    if (classification.counts.length === 1) {
      setPointStyle(MaterialType.RGBA);
      viewerRef?.current?.threeViewer?.classification.switchPointCloudMaterial(
        MaterialType.RGBA
      );
    } else {
      setPointStyle(MaterialType.CLASSIFICATION);
      viewerRef?.current?.threeViewer?.classification.switchPointCloudMaterial(
        MaterialType.CLASSIFICATION
      );
    }

    const classes = classification.counts.map((c: { value: number }) => {
      const color =
        CaptureMaterialColorsLookup[
          CaptureMaterialClassifications[
            CaptureMaterialClassifications[
              c.value
            ] as keyof typeof CaptureMaterialClassifications
          ]
        ];
      return {
        label: c.value,
        labelString: Object.values(CaptureMaterialClassificationsLabels)[
          c.value
        ],
        color: color,
        visible: true,
      } as PotreeViewer2ControllerInfoClassificationProps;
    }) as PotreeViewer2ControllerInfoClassificationProps[];

    classes.forEach((c) => {
      viewerRef?.current?.threeViewer?.classification.updateClassificationColorById(
        c.label,
        c.color
      );
    });

    setClassificationClasses(classes);

    return;
  }, [eptJson]);

  useEffect(() => {
    if (!classificationClasses) {
      return;
    }

    for (const classification of classificationClasses) {
      viewerRef?.current?.threeViewer?.classification.setClassificationVisibilityById(
        classification.label,
        classification.visible
      );
      viewerRef?.current?.threeViewer?.classification.updateClassificationColorById(
        classification.label,
        classification.color
      );
    }
  }, [classificationClasses]);

  const mouseMove = useCallback(
    (event: MouseEvent) => {
      if (
        !viewerReady ||
        !potreeRenderAreaRef.current ||
        !viewerRef?.current?.threeViewer?.viewer ||
        !viewerRef?.current?.threeViewer?.camera ||
        !event
      ) {
        return;
      }
      // find the x y for the mouse in the potreeRenderAreaRef
      // no matter what event.target is
      const rect = potreeRenderAreaRef.current.getBoundingClientRect();
      const mouse = new Vector2();
      mouse.x = event.clientX - rect.left;
      mouse.y = event.clientY - rect.top;

      const pnt: {
        point: {
          position: Vector3;
        };
      } = Potree.Utils.getMousePointCloudIntersection(
        new Vector2(mouse.x, mouse.y),
        viewerRef?.current?.threeViewer?.camera,
        viewerRef?.current?.threeViewer.viewer,
        viewerRef?.current?.threeViewer?.viewer.scene?.pointclouds
      );
      mousePosition.current = pnt?.point?.position;
    },
    [viewerReady, potreeRenderAreaRef]
  );

  useEffect(() => {
    potreeRenderAreaRef?.current?.addEventListener(
      'mousemove',
      mouseMove,
      false
    );
    return () => {
      potreeRenderAreaRef?.current?.removeEventListener(
        'mousemove',
        mouseMove,
        false
      );
    };
  }, [potreeRenderAreaRef, mouseMove]);

  useEffect(() => {
    const mouse = new Vector2();
    const raycaster = new Raycaster();
    raycaster.params.Sprite = { threshold: 0.1 };

    const mouseDown = (event: MouseEvent) => {
      if (
        !viewerReady ||
        !potreeRenderAreaRef.current ||
        !viewerRef?.current?.threeViewer?.viewer ||
        !viewerRef?.current?.threeViewer?.camera ||
        !event
      ) {
        return;
      }

      const pnt: {
        point: {
          position: Vector3;
        };
      } = Potree.Utils.getMousePointCloudIntersection(
        new Vector2(event.offsetX, event.offsetY),
        viewerRef?.current?.threeViewer?.camera,
        viewerRef?.current?.threeViewer.viewer,
        viewerRef?.current?.threeViewer?.viewer.scene?.pointclouds
      );
      mousePosition.current = pnt?.point?.position;

      const rect = potreeRenderAreaRef.current.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, viewerRef?.current?.threeViewer?.camera);
      if (!viewerRef?.current?.threeViewer?.scene?.children) return;
      const intersects = raycaster.intersectObjects(
        viewerRef.current.threeViewer.scene.children,
        true
      );

      if (!intersects[0]?.object) return;
      const selectedObject: ICustomMesh = intersects[0]?.object as ICustomMesh;
      if (
        selectedObject.customType === Annotation3dPoints.CaptureImageLocation
      ) {
        selectedObject.callback(selectedObject.uniqueId);
      }
    };

    potreeRenderAreaRef?.current?.addEventListener(
      'mousedown',
      mouseDown,
      false
    );
    return () => {
      potreeRenderAreaRef?.current?.removeEventListener(
        'mousedown',
        mouseDown,
        false
      );
    };
  }, [potreeRenderAreaRef, viewerReady]);

  const updateClassificationClassByLabel = useCallback(
    (label: number, args: { visible?: boolean; color?: number[] }) => {
      if (!classificationClasses) {
        return;
      }

      if (!classificationClasses.find((c) => c.label === label)) {
        return;
      }

      setClassificationClasses((prev) =>
        prev?.map((c) =>
          c.label !== label
            ? c
            : {
                ...c,
                visible: args?.visible ?? c.visible,
                color: args?.color ?? c.color,
              }
        )
      );
    },
    [classificationClasses]
  );

  const loadPointcloud = useCallback(
    async (eptId: number) => {
      if (!viewerReady) {
        return;
      }
      setViewerError(undefined);

      const opts = {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
          'cache-control': 'no-cache',
          pragma: 'no-cache',
        },
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
      };
      viewerRef?.current?.threeViewer
        ?.load(
          'ept.json',
          `${environment.api_server_url}/api/EptPointcloud/${eptId}/ept.json`,
          opts
        )
        .then((ept) => {
          setEptJson(ept);
          setSceneLoaded(true);
        })
        .catch((e) => {
          setViewerError(e);
        });
    },
    [viewerReady, token]
  );

  const loadPointcloudFromAzure = useCallback(
    async (url: string) => {
      if (!viewerReady) {
        return;
      }
      setViewerError(undefined);
      const azureSAS = new URL(url).searchParams;

      const opts = {
        azureSAS: `?${azureSAS}`,
        isAzure: true,
        method: 'GET',
        cache: 'no-cache',
      };

      viewerRef?.current?.threeViewer
        ?.load('ept.json', url, opts)
        .then((ept) => {
          setEptJson(ept);
          setSceneLoaded(true);
        })
        .catch((e) => {
          setViewerError(e);
        });
    },
    [viewerReady]
  );

  const removePointcloud = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.clearPointCloudScene();
    setClassificationClasses(undefined);
    setEptJson(undefined);
    setAnnotations3dGeometry?.(undefined);
  }, [viewerReady, setAnnotations3dGeometry]);

  const loadCameraPositions = useCallback(
    (images: APIModels.CaptureImage[], addPrisms = false) => {
      if (!viewerReady) {
        return;
      }

      const filteredImages = images?.filter(
        (image) => image.x && image.y && image.z
      );

      if (!filteredImages?.length) {
        return;
      }
      filteredImages.forEach((image, i) => {
        const x = image.x ?? undefined;
        const y = image.y ?? undefined;
        const z = image.z ?? undefined;
        const roll = image.roll ?? undefined;
        const pitch = image.pitch ?? undefined;
        const yaw = image.yaw ?? undefined;
        const id = image.id as number;

        if (!x || !y || !z || !id) return;

        viewerRef?.current?.threeViewer?.addCameraLocationMarker(
          id.toString(),
          new Vector3(x, y, z)
        );

        if (!roll || !pitch || !yaw) return;
        if (addPrisms) {
          const pyramid = createPyramid(x, y, z, roll, pitch, yaw);
          viewerRef?.current?.threeViewer?.addPrism(pyramid);
        }
        viewerRef?.current?.threeViewer?.indexImageLocation(
          id,
          x,
          y,
          z,
          roll,
          pitch,
          yaw
        );
      });
    },
    [viewerReady]
  );

  const removeCameraPositions = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.removeAllMeshes();
  }, [viewerReady]);

  const setSelectedTool = useCallback(
    (tool: MeasurementOptionsValues) => {
      if (!viewerReady) {
        return;
      }
      viewerRef?.current?.threeViewer?.setTool(tool);
    },
    [viewerReady]
  );

  const removeMeasurements = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.removeAllMeasurements();
  }, [viewerReady]);

  useEffect(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.showHideAllMeasurements(
      measurementsVisible
    );
  }, [measurementsVisible, viewerReady]);

  const resetView = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.resetView();
  }, [viewerReady]);

  const takeScreenshot = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    const potreeRenderArea = document.getElementById('potree_render_area');
    const canvas = potreeRenderArea?.getElementsByTagName('canvas')[0];
    if (!canvas) return;
    const a = document.createElement('a');
    a.href = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    a.download = createFilename('ap-cloud', 'png');
    a.click();
    a.remove();
  }, [viewerReady]);

  const setCameraSettings = useCallback(() => {
    setTimeout(() => {
      viewerRef?.current?.threeViewer?.setCameraSettings(
        JSON.parse(selectedCaptureJob?.cameraSettings || '{}')
      );
    }, 0);
  }, [viewerRef, selectedCaptureJob]);

  const setBackground = useCallback(
    (background: BackgroundOptionsValues) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.setBackground(background);
    },
    [viewerReady]
  );

  const removeObjects = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    objectCount.current = 0;
    viewerRef?.current?.threeViewer?.clearAllMarkers();
    viewerRef?.current?.threeViewer?.removeFeatureInfoModal();
    viewerRef?.current?.threeViewer?.clearAllTrunkLines();
  }, [viewerReady]);

  const addObject = useCallback(
    ({
      captureObject,
      markerVisible,
      labelVisible,
      editable,
      type,
      clickable,
      includeApfGeometry,
    }: PotreeViewer2AddObjectProps) => {
      if (!viewerReady) {
        return;
      }

      const x = captureObject.x;
      const y = captureObject.y;
      const z = captureObject.z;
      const id = captureObject.id?.toString();

      if (!x || !y || !z || !id) {
        return;
      }

      const color =
        markerColorPalette[objectCount.current % markerColorPalette.length];

      viewerRef?.current?.threeViewer?.addExistingObjectLocation(
        EventBusNames.CaptureObjectPositionChanged,
        id,
        new Vector3(x, y, z),
        {
          fill: color,
          name: captureObject.name || '',
          visible: markerVisible ?? true,
          visibleLabel: labelVisible ?? true,
          type: type ?? CaptureObjectMarkerType.ExtractionJob,
          editable: editable ?? false,
          clickable: clickable ?? false,
        }
      );
      objectCount.current++;

      if (captureObject?.geom2D?.coordinates?.length && includeApfGeometry) {
        const path = captureObject?.geom2D?.coordinates[0] as number[][];
        const coId = captureObject?.id?.toString();
        if (!path || !coId) return;
        viewerRef?.current?.threeViewer?.drawTrunkLine(path, coId);
      }
    },
    [viewerReady]
  );

  const bindObjectOnClick = useCallback(
    (callback: ((e: CustomEvent) => void) | undefined) => {
      if (!viewerReady) {
        return;
      }

      if (callback === undefined && objectOnClickRef.current) {
        eventBus.remove(
          EventBusNames.CaptureObjectClicked,
          objectOnClickRef.current.callback,
          objectOnClickRef.current.eventId
        );
        objectOnClickRef.current = undefined;
        return;
      }

      if (callback !== undefined) {
        objectOnClickRef.current = {
          eventId: eventBus.on(
            EventBusNames.CaptureObjectClicked,
            callback,
            true
          ),
          callback: callback,
        };
      }
    },
    [viewerReady]
  );

  const bindCameraPositionOnClick = useCallback(
    (callback: ((e: CustomEvent) => void) | undefined) => {
      if (!viewerReady) {
        return;
      }

      if (callback === undefined && cameraPositionOnClickRef.current) {
        eventBus.remove(
          EventBusNames.Point3dLocationMarkerClicked,
          cameraPositionOnClickRef.current.callback,
          cameraPositionOnClickRef.current.eventId
        );
        cameraPositionOnClickRef.current = undefined;
        return;
      }

      if (callback !== undefined) {
        cameraPositionOnClickRef.current = {
          eventId: eventBus.on(
            EventBusNames.Point3dLocationMarkerClicked,
            callback,
            true
          ),
          callback: callback,
        };
      }
    },
    [viewerReady]
  );

  const zoomToLocation = useCallback(
    ({ loc, distance }: PotreeViewer2ZoomToLocationProps) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.zoomToLocation(loc, distance);
    },
    [viewerReady]
  );

  const getCameraSettings = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    return viewerRef?.current?.threeViewer?.getCameraSettings();
  }, [viewerReady]);

  const removeInfoModal = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.removeFeatureInfoModal();
  }, [viewerReady]);

  const addInfoModal = useCallback(
    ({ element, loc }: PotreeViewer2InfoModalProps) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.removeFeatureInfoModal();

      const position = new Vector3(loc.x, loc.y, loc.z);

      viewerRef?.current?.threeViewer?.addFeatureInfoModal(position, element);
    },
    [viewerReady]
  );

  const setCameraFrontView = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.setCameraFrontView();
  }, [viewerReady]);

  // stopObjectSelection
  const stopObjectSelection = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.stopObjectSelection();
  }, [viewerReady]);

  //resetAllObjectMarkers
  const resetAllObjectMarkers = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.resetAllObjectMarkers();
  }, [viewerReady]);

  //getHighlightedMarkers
  const getHighlightedMarkers = useCallback(() => {
    if (!viewerReady) {
      return [];
    }

    return viewerRef?.current?.threeViewer?.getHighlightedMarkers() || [];
  }, [viewerReady]);

  // stopCropBox
  const stopCropBox = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.stopCropBox();
  }, [viewerReady]);

  // startCropBox
  const startCropBox = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.startCropBox();
  }, [viewerReady]);

  // selectObjectsByLocation2D
  const selectObjectsByLocation2D = useCallback(
    (points: Vector3[]) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.selectObjectsByLocation2D(points);
    },
    [viewerReady]
  );

  // get3DPointFromXYZ
  const get3DPointFromXYZ = useCallback(
    (x: number, y: number) => {
      if (!viewerReady) {
        return;
      }

      return viewerRef?.current?.threeViewer?.get3DPointFromXYZ(x, y);
    },
    [viewerReady]
  );

  //getCropBoxCoordinates
  const getCropBoxCoordinates = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    return viewerRef?.current?.threeViewer?.getCropBoxCoordinates();
  }, [viewerReady]);

  //startObjectSelection
  const startObjectSelection = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.startObjectSelection();
  }, [viewerReady]);

  // drawConvexHull
  const drawConvexHull = useCallback(
    (points: number[][], coId: string) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.drawConvexHull(points, coId);
    },
    [viewerReady]
  );

  // drawTrunkLine
  const drawTrunkLine = useCallback(
    (points: number[][], coId: string) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.drawTrunkLine(points, coId);
    },
    [viewerReady]
  );

  // addExistingObjectLocation
  const addExistingObjectLocation = useCallback(
    (
      eventName: EventBusNames,
      eventId: string,
      position: Vector3,
      options: MarkerObjOptions
    ) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.addExistingObjectLocation(
        eventName,
        eventId,
        position,
        options
      );
    },
    [viewerReady]
  );

  // clearAllMarkers
  const clearAllMarkers = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.clearAllMarkers();
  }, [viewerReady]);

  // toggleObjectVisibility
  const toggleObjectVisibility = useCallback(
    (id: string, show: boolean) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.toggleObjectVisibility(id, show);
    },
    [viewerReady]
  );

  // toggleTextLabelById
  const toggleTextLabelById = useCallback(
    (id: string, show: boolean) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.toggleTextLabelById(id, show);
    },
    [viewerReady]
  );

  // clearAllTrunkLines
  const clearAllTrunkLines = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.clearAllTrunkLines();
  }, [viewerReady]);

  // axesHelper
  const addAxesHelper = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.addAxesHelper();
  }, [viewerReady]);

  //removeAxesHelper
  const removeAxesHelper = useCallback(() => {
    if (!viewerReady) {
      return;
    }

    viewerRef?.current?.threeViewer?.removeAxesHelper();
  }, [viewerReady]);

  const toggleAll3dAnnotations = useCallback(
    (show: boolean) => {
      if (!viewerReady) {
        return;
      }

      viewerRef?.current?.threeViewer?.toggleAll3dAnnotations(show);
    },
    [viewerReady]
  );

  useEffect(() => {
    // destroyCroppingTool();

    setCropBoxEditingActive(false);
  }, [captureJobMetadata?.id, captureJobMetadata?.uuid]);

  useEffect(() => {
    viewerRef.current?.threeViewer?.setCropBoxEditingActive(
      cropBoxEditingActive
    );
  }, [cropBoxEditingActive]);

  const frameUpdate = useCallback(
    (frameEvent: FrameEvent | null) => {
      if (!frameEvent) {
        const pointCloudBBox: { min: Vector3; max: Vector3 } | undefined =
          viewerRef.current?.threeViewer?.findBoundingBox();
        const defaultFrameEvent = {
          frame: {
            min: pointCloudBBox?.min || new Vector3(-1, -1, -1),
            max: pointCloudBBox?.max || new Vector3(1, 1, 1),
          },
        };
        frameEvent = defaultFrameEvent;
      }
      setCroppingFrame(frameEvent);
    },
    [viewerRef]
  );

  // setup initial cropbox and observable listener
  useEffect(() => {
    if (!viewerReady || !sceneLoaded || !cloudApp) {
      return;
    }
    viewerRef.current?.threeViewer?.destroyCroppingTool();
    let bBox = null;
    const pointCloudBBox: { min: Vector3; max: Vector3 } | undefined =
      viewerRef.current?.threeViewer?.findBoundingBox();

    const savedValue = JSON.parse(captureJobMetadata?.cropbox || '{}');
    const isAllZero =
      Object.values(savedValue?.frame?.min || {}).every((v) => v === 0) &&
      Object.values(savedValue?.frame?.max || {}).every((v) => v === 0);

    if (!savedValue || !savedValue.frame || isAllZero) {
      if (!pointCloudBBox) {
        return;
      }
      bBox = {
        frame: {
          min: pointCloudBBox.min,
          max: pointCloudBBox.max,
        },
      };
    } else {
      bBox = savedValue;
    }
    viewerRef.current?.threeViewer?.initializeCropBoxEditTool(
      viewerRef.current?.threeViewer?.updateBoxVolume,
      viewerRef.current?.threeViewer.viewer
    );
    viewerRef.current?.threeViewer?.potreeFrameEvent?.subscribe(frameUpdate);

    viewerRef.current?.threeViewer?.initWithExistingFrame(
      [bBox.frame.min, bBox.frame.max],
      [
        pointCloudBBox?.min || new Vector3(-100, -100, -100),
        pointCloudBBox?.max || new Vector3(100, 100, 100),
      ]
    );
    viewerRef.current?.threeViewer?.updateBoxVolume(bBox, true);
  }, [viewerRef, viewerReady, captureJobMetadata, sceneLoaded, cloudApp]);

  const destroyCroppingTool = useDestroyCroppingTool(
    viewerRef,
    viewerReady,
    captureJobMetadata,
    setCropBoxEditingActive
  );

  const resetCroppingTool = useResetCropEditingTool(
    viewerRef,
    viewerReady,
    captureJobMetadata,
    setCropBoxEditingActive
  );

  const toggleCropEditingTool = useToggleCropEditingTool(
    viewerRef,
    viewerReady,
    cropBoxEditingActive,
    setCropBoxEditingActive
  );

  const finishCropEditingTool = useFinishCropEditingTool(
    viewerRef,
    viewerReady,
    croppingFrame,
    captureJobMetadata,
    setCropBoxEditingActive
  );

  const resetCropBox = useResetCropBox(
    viewerRef,
    viewerReady,
    captureJobMetadata,
    setCropBoxEditingActive
  );

  const startCropBoxEditingTool = useStartCropEditingTool(
    viewerRef,
    viewerReady,
    setCropBoxEditingActive
  );

  const pauseOrbitControls = usePauseOrbitControls(
    viewerRef,
    viewerReady,
    true
  );
  const resumeOrbitControls = useResumeOrbitControls(
    viewerRef,
    viewerReady,
    true
  );

  const controller: IPotreeViewerController = useMemo(
    () => ({
      info: {
        error: viewerError,
        viewerInitialized: false,
        viewerReady,
        measurementsVisible,
        captureJobMetadata,
        pointStyle,
        cameraPositionsVisible,
        sceneLoaded,
        classificationClasses,
        captureMetadata: captureQuery.data,
        cropBoxEditingActive,
      },
      threeViewer: viewerRef?.current?.threeViewer,
      setCameraPositionsVisible,
      setCaptureJobMetadata,
      loadPointcloud,
      removePointcloud,
      setPointStyle,
      loadCameraPositions,
      removeCameraPositions,
      setSelectedTool,
      setMeasurementsVisible,
      removeMeasurements,
      resetView,
      takeScreenshot,
      setCameraSettings,
      setBackground,
      getCameraSettings,
      addObject,
      removeObjects,
      zoomToLocation,
      addInfoModal,
      bindObjectOnClick,
      removeInfoModal,
      updateClassificationClassByLabel,
      bindCameraPositionOnClick,
      setCameraFrontView,
      stopObjectSelection,
      resetAllObjectMarkers,
      getHighlightedMarkers,
      startCropBox,
      stopCropBox,
      selectObjectsByLocation2D,
      get3DPointFromXYZ,
      getCropBoxCoordinates,
      startObjectSelection,
      drawConvexHull,
      drawTrunkLine,
      addExistingObjectLocation,
      clearAllMarkers,
      toggleObjectVisibility,
      toggleTextLabelById,
      clearAllTrunkLines,
      addAxesHelper,
      removeAxesHelper,
      mousePosition,
      toggleAll3dAnnotations,
      loadPointcloudFromAzure,
      setCaptureMetadata,
      toggleCropEditingTool,
      destroyCroppingTool,
      finishCropEditingTool,
      resetCropBox,
      startCropBoxEditingTool,
      resetCroppingTool,
      pauseOrbitControls,
      resumeOrbitControls,
    }),
    [
      viewerError,
      viewerReady,
      pointStyle,
      measurementsVisible,
      captureJobMetadata,
      classificationClasses,
      setCaptureJobMetadata,
      loadPointcloud,
      removePointcloud,
      setPointStyle,
      loadCameraPositions,
      removeCameraPositions,
      setSelectedTool,
      setMeasurementsVisible,
      removeMeasurements,
      resetView,
      takeScreenshot,
      setCameraSettings,
      setBackground,
      getCameraSettings,
      addObject,
      removeObjects,
      zoomToLocation,
      addInfoModal,
      bindObjectOnClick,
      removeInfoModal,
      cameraPositionsVisible,
      setCameraPositionsVisible,
      updateClassificationClassByLabel,
      bindCameraPositionOnClick,
      setCameraFrontView,
      stopObjectSelection,
      resetAllObjectMarkers,
      getHighlightedMarkers,
      startCropBox,
      stopCropBox,
      selectObjectsByLocation2D,
      get3DPointFromXYZ,
      getCropBoxCoordinates,
      startObjectSelection,
      drawConvexHull,
      drawTrunkLine,
      addExistingObjectLocation,
      clearAllMarkers,
      toggleObjectVisibility,
      toggleTextLabelById,
      clearAllTrunkLines,
      addAxesHelper,
      removeAxesHelper,
      viewerRef.current?.threeViewer,
      mousePosition.current,
      sceneLoaded,
      toggleAll3dAnnotations,
      loadPointcloudFromAzure,
      toggleCropEditingTool,
      captureMetadata,
      cropBoxEditingActive,
      destroyCroppingTool,
      finishCropEditingTool,
      startCropBoxEditingTool,
      cropBoxEditingActive,
      resetCroppingTool,
      pauseOrbitControls,
      resumeOrbitControls,
      resetCropBox,
    ]
  );

  useEffect(() => {
    setController?.(controller);
  }, [controller, setController]);

  useEffect(() => {
    if (!viewerReady || !sceneLoaded) {
      return;
    }

    viewerRef?.current?.threeViewer?.classification.switchPointCloudMaterial(
      pointStyle
    );
  }, [pointStyle, viewerReady, sceneLoaded]);

  const initializePotreeViewer = useCallback(async () => {
    if (!viewerRef.current || viewerInitialized) return;
    await viewerRef?.current?.threeViewer.initialize();
    setViewerReady(true);
  }, [viewerRef.current, setViewerReady, viewerInitialized]);

  useEffect(() => {
    if (!potreeRenderAreaRef.current || viewerInitialized) return;
    const _viewer = new Viewer(permissions, potreeRenderAreaRef.current);

    setViewerInitialized(true);
    viewerRef.current = {
      threeViewer: _viewer,
    };
    initializePotreeViewer();

    return () => {
      _viewer.destroy();
      viewerRef.current = undefined;
      setViewerReady(false);
    };
  }, []);

  useEffect(() => {
    if (hasDebugPermission && controller?.info.viewerReady) {
      controller?.addAxesHelper();
    } else {
      controller?.removeAxesHelper();
    }
  }, [hasDebugPermission, controller?.info.viewerReady]);

  // cleanup
  useEffect(() => {
    return () => {
      setAnnotations3dGeometry?.(undefined);
    };
  }, []);

  return (
    <div className="potree_container w-full h-full bg-gray-900 relative">
      {showTools && <PotreeTools viewerController={controller} />}
      {(showCloudTools || showShareTools) && (
        <PotreeCloudTools
          viewerController={controller}
          shared={showShareTools}
        />
      )}
      {(showCloudTools || showShareTools) && viewerReady && (
        <SharedThreeDAnnotationToolbar
          viewerController={controller}
          croppingActive={cropBoxEditingActive}
          isPotree={true}
          shareApp={showShareTools}
        />
      )}
      {cropBoxEditingActive && (
        <SharedCroppingToolbar viewerController={controller} />
      )}
      {plugins?.map((plugin) => plugin)}
      <div id="potree_render_area" ref={potreeRenderAreaRef} />
    </div>
  );
};
