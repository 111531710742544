import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Vector2 } from 'three';

import { AnnotationsController } from '@agerpoint/three-d-viewer';
import {
  ICustomLine,
  ICustomMesh,
  IGs3dViewerController,
  IPotreeViewerController,
} from '@agerpoint/types';

export const useClickHandler = (
  viewerController: IPotreeViewerController | IGs3dViewerController,
  annoCtrl: MutableRefObject<AnnotationsController | null>,
  selectedAnnotation: null | ICustomLine | ICustomMesh,
  targetEl: HTMLCanvasElement | null
) => {
  const HOLD_DURATION = 251;
  const DRAG_THRESHOLD = 5; // Minimum movement (in pixels) to detect as drag

  const clickTimeout = useRef<null | NodeJS.Timeout>(null);
  const holdTimeout = useRef<null | NodeJS.Timeout>(null);
  const holdStartTime = useRef<null | number>(null);
  const preventSingleClick = useRef(false);
  const clickRegistered = useRef(false);
  const initialMousePos = useRef<Vector2 | null>(null); // Store initial mouse position
  const isDragging = useRef(false); // Track whether dragging is in progress
  const dragStarted = useRef(false); // Track if drag has started (only trigger once)
  const holdTriggered = useRef(false); // Track if the hold action triggered object3dMouseDown

  // New ref to store the selected annotation at mouse down
  const currentAnnotation = useRef<null | ICustomLine | ICustomMesh>(null);

  // Function to calculate distance moved
  const getDistanceMoved = (event: MouseEvent) => {
    if (!initialMousePos.current) return 0;
    const deltaX = event.clientX - initialMousePos.current.x;
    const deltaY = event.clientY - initialMousePos.current.y;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  };

  // Helper function to clear timeouts
  const clearHoldTimeout = () => {
    if (holdTimeout.current) {
      clearTimeout(holdTimeout.current);
    }
  };

  const clearClickTimeout = () => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }
  };

  const clearTimeouts = useCallback(() => {
    clearHoldTimeout();
    clearClickTimeout();
  }, []);

  // Single-click action handler
  const handleSingleClick = useCallback(() => {
    if (currentAnnotation.current) {
      annoCtrl.current?.object3dMouseDown(currentAnnotation.current);
    } else {
      annoCtrl.current?.sceneWasClicked();
    }
    annoCtrl.current?.mouseUp();
    viewerController.resumeOrbitControls();
  }, [annoCtrl, viewerController]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!initialMousePos.current) return;

      const distanceMoved = getDistanceMoved(event);

      // If the distance moved exceeds the threshold, mark as dragging
      if (
        distanceMoved > DRAG_THRESHOLD &&
        currentAnnotation.current &&
        holdTimeout.current &&
        !holdTriggered.current // Ensure hold hasn't triggered object3dMouseDown
      ) {
        isDragging.current = true;
        // Only trigger object3dMouseDown once per drag
        if (!dragStarted.current) {
          dragStarted.current = true; // Set flag to prevent multiple triggers
          clearTimeouts(); // Clear any pending click/hold timeouts when dragging starts
          annoCtrl.current?.object3dMouseDown(currentAnnotation.current); // Trigger drag action only once
        }
      }
    },
    [annoCtrl]
  );

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (event.button !== 0) return; // Left-click only

      holdStartTime.current = Date.now();
      clickRegistered.current = false;
      dragStarted.current = false; // Reset the drag started flag for a new drag
      holdTriggered.current = false; // Reset hold triggered flag for a new interaction

      // Record the initial mouse position
      initialMousePos.current = new Vector2(event.clientX, event.clientY);
      isDragging.current = false; // Reset dragging state
      // Capture selected annotation immediately on mouse down
      currentAnnotation.current = selectedAnnotation;

      // Pause orbit controls to allow drag
      if (currentAnnotation.current) {
        viewerController.pauseOrbitControls();
      }

      // Start hold detection
      holdTimeout.current = setTimeout(() => {
        if (
          currentAnnotation.current &&
          !isDragging.current &&
          !dragStarted.current
        ) {
          holdTriggered.current = true;
          viewerController.pauseOrbitControls();
          annoCtrl.current?.object3dMouseDown(currentAnnotation.current);
        }
        clearClickTimeout();
        clickRegistered.current = true;
      }, HOLD_DURATION);
    },
    [annoCtrl, selectedAnnotation, viewerController]
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      if (event.target !== targetEl || event.button !== 0) return; // Left-click only

      // If dragging, skip click logic
      if (isDragging.current) {
        annoCtrl.current?.mouseUp();
        viewerController.resumeOrbitControls();
        initialMousePos.current = null;
        isDragging.current = false;
        dragStarted.current = false; // Reset drag started flag after mouse up
        holdTriggered.current = false; // Reset hold triggered flag after mouse up
        return;
      }

      const holdDuration = Date.now() - (holdStartTime.current || 0);
      clearHoldTimeout();

      if (
        holdDuration < HOLD_DURATION &&
        !preventSingleClick.current &&
        !clickRegistered.current
      ) {
        clickTimeout.current = setTimeout(() => {
          if (!preventSingleClick.current) {
            handleSingleClick();
            clickRegistered.current = true;
          }
          preventSingleClick.current = false;
        }, 250);
      } else {
        annoCtrl.current?.mouseUp();
        viewerController.resumeOrbitControls();
      }
      // Clear mouse position and dragging state
      initialMousePos.current = null;
      isDragging.current = false;
    },
    [viewerController, handleSingleClick]
  );

  const handleDoubleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      viewerController.pauseOrbitControls();
      if (event.button !== 0) return; // Left-click only

      preventSingleClick.current = true;
      clearClickTimeout();

      if (currentAnnotation.current) {
        annoCtrl.current?.object3dDoubleClick(currentAnnotation.current);
      } else {
        annoCtrl.current?.sceneWasDoubleClicked();
      }
      viewerController.resumeOrbitControls();
    },
    [annoCtrl, viewerController]
  );

  useEffect(() => {
    if (!targetEl) return;

    targetEl.addEventListener('mousedown', handleMouseDown);
    targetEl.addEventListener('mouseup', handleMouseUp);
    targetEl.addEventListener('dblclick', handleDoubleClick);
    targetEl.addEventListener('mousemove', handleMouseMove);

    return () => {
      clearTimeouts();
      targetEl.removeEventListener('mousedown', handleMouseDown);
      targetEl.removeEventListener('mouseup', handleMouseUp);
      targetEl.removeEventListener('dblclick', handleDoubleClick);
      targetEl.removeEventListener('mousemove', handleMouseMove);
    };
  }, [
    handleMouseDown,
    handleMouseUp,
    handleDoubleClick,
    handleMouseMove,
    viewerController,
    clearTimeouts,
    targetEl,
  ]);

  return {};
};
