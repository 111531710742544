import EventEmitter from 'events';
import {
  OrthographicCamera,
  PerspectiveCamera,
  Plane,
  Scene,
  Vector3,
} from 'three';

import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  HexColor,
  IAnnotationBase,
  IAnnotations2dGeometry,
  IAnnotations3dGeometry,
} from '@agerpoint/types';
import { CustomMesh } from '@agerpoint/utilities';

import { AnnotationsStore } from './annotations-store/annotations-store';

export class AnnotationBase extends EventEmitter implements IAnnotationBase {
  static annoStore: AnnotationsStore = new AnnotationsStore();
  static frustumPlanes: Plane[] = [];
  static setFrustumPlanes(planes: Plane[]) {
    this.frustumPlanes = planes;
  }
  scene: Scene;
  perspectiveCamera: PerspectiveCamera;
  canvas: HTMLCanvasElement | undefined;
  isPotree: boolean;
  color: ColorsThreeD | HexColor = ColorsThreeD.Cyan;
  protected isReadOnly: boolean;

  private selectedObject: {
    id: string;
    type:
      | Annotation3dPoints
      | Annotation3dLines
      | Annotation3dPolygons
      | Annotation2dPoints
      | undefined;
  } | null = null;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    isReadOnly = false,
    canvas?: HTMLCanvasElement
  ) {
    super();
    this.scene = scene;
    this.isReadOnly = isReadOnly;
    if (canvas) {
      this.canvas = canvas;
    }
    this.perspectiveCamera = perspectiveCamera;
    this.isPotree = isPotree;
  }

  destroy() {
    this.removeAllListeners();
  }

  public notifyListeners() {
    const annotationGeom = AnnotationBase.annoStore.get3dAnnotationGeometry();
    const annotationGeomWithSelected = {
      ...annotationGeom,
      selected: {
        id: this.selectedObject?.id || '',
        type: this.selectedObject?.type,
      },
    } as IAnnotations3dGeometry;

    const annotation2dGeom = AnnotationBase.annoStore.get2dAnnotationGeometry();
    const annotation2dGeomWithSelected = {
      ...annotation2dGeom,
      selected: {
        id: this.selectedObject?.id || '',
        type: this.selectedObject?.type,
      },
    } as IAnnotations2dGeometry;

    AnnotationBase.annoStore.debouncedDispatch(
      annotationGeomWithSelected,
      annotation2dGeomWithSelected
    );
  }

  public unHighlightEverything() {
    AnnotationBase.annoStore.unHighlightAll3d();
    AnnotationBase.annoStore.unHighlightAll2d();
  }
  /**
   * Set the selected object.
   * @private
   * @param {string} id - The ID of the object.
   * @param {Annotation3dPoints|Annotation3dLines|Annotation3dPolygons} type - The type of the object.
   */
  setSelectedObject(
    id: string,
    type:
      | Annotation3dPoints
      | Annotation3dLines
      | Annotation3dPolygons
      | Annotation2dPoints
      | undefined
  ) {
    this.selectedObject = { id, type };
    this.notifyListeners();
  }

  /**
   * Unhighlight all 3D annotations.
   * @returns {void}
   */
  public unHighlightAll() {
    AnnotationBase.annoStore.unHighlightAll2d();
    AnnotationBase.annoStore.unHighlightAll3d();
    this.selectedObject = null;
    this.notifyListeners();
  }
  public removePoint3dById(id: string) {
    AnnotationBase.annoStore.removePoint3dById(id);
  }
  public removePoint2dById(id: string) {
    AnnotationBase.annoStore.removePoint2dById(id);
  }
  public removeLine3dById(id: string) {
    AnnotationBase.annoStore.removeLine3dById(id);
  }
  public removePolygon3dById(id: string) {
    AnnotationBase.annoStore.removePolygon3dById(id);
  }

  public isOutsideFrustum(position: Vector3) {
    if (!AnnotationBase.frustumPlanes.length) {
      return false;
    }
    for (let i = 0; i < 6; i++) {
      if (AnnotationBase.frustumPlanes[i].distanceToPoint(position) < 0) {
        return true; // The position is outside the frustum
      }
    }
    return false; // The position is inside the frustum
  }

  scaleObjectToScreenSpace(object: CustomMesh) {
    const distance = this.perspectiveCamera.position.distanceTo(
      object.position
    );
    const scaleFactor = distance / 1;
    object.scale.set(scaleFactor, scaleFactor, scaleFactor);
  }
}
