import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import {
  AnalyticRequestStatus,
  JobTypeCategory,
  LdFlags,
} from '@agerpoint/types';
import {
  hasPermission,
  useGlobalStore,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useOpsAnalyticRequestsQueries = ({
  filter,
  extractionJobId,
  captureJobId,
  pipelineJobId,
  operationJobId,
  captureId,
  customerId,
}: {
  filter?: APIModels.AnalyticRequestFilter;
  extractionJobId?: number | undefined;
  captureJobId?: number | undefined;
  pipelineJobId?: number | undefined;
  operationJobId?: number | undefined;
  captureId?: number | undefined;
  customerId?: number | undefined;
}) => {
  const queryClient = useQueryClient();

  const toasts = useToasts();

  const { analyticRequestId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { permissions } = useGlobalStore();

  const hasAnalyticRequestManagementPermission = useMemo(() => {
    return hasPermission(LdFlags.AnalyticRequestManagement, permissions);
  }, [permissions]);

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const analyticRequestsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.analyticRequests,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    queryFn: ({ pageParam }) =>
      APIClient.getFilteredAnalyticRequests(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.AnalyticRequestFilter
      ),
    enabled: filter !== undefined,
    staleTime: APIUtils.getDuration({ seconds: 20 }),
  });

  const analyticsQuery = APIClient.useGetAnalytic({
    query: {
      queryKey: [APIUtils.QueryKey.analytics],
      select: (data) =>
        APIUtils.Sort.analytics(data.filter((d) => d.archived === false)),
    },
  });

  const analyticRequestQuery = APIClient.useGetAnalyticRequestById(
    Number(analyticRequestId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: Number(analyticRequestId) },
        ],
        placeholderData: () =>
          APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Block>({
            queryClient,
            queryKey: [
              APIUtils.QueryKey.analyticRequests,
              APIUtils.QueryKey.infinite,
            ],
            id: Number(analyticRequestId),
            accessor: 'id',
          }),
        retry: 0,
        enabled: Number.isSafeInteger(Number(analyticRequestId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const analyticsLookupTable = useLookupTable(analyticsQuery.data, 'id');

  const analyticRequestPutMutation = APIClient.usePutAnalyticRequestById({
    mutation: {
      mutationFn: async (data: {
        id: number;
        data: APIModels.AnalyticRequest;
      }) => {
        const copy = cloneDeep(data.data);

        delete copy.createdByProfile;
        delete copy.customerAnalytic;

        return APIClient.putAnalyticRequestById(data.id, copy);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.analyticRequests],
        });
      },

      onSuccess: (_, variable) => {
        APIUtils.updateInfiniteQueryCache({
          queryClient,
          queryKey: [APIUtils.QueryKey.analyticRequests],
          accessor: 'id',
          id: variable.id,
          data: variable.data,
        });

        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: variable.id },
          ],
          data: variable.data,
        });

        toasts.add(toasts.prepare.entityUpdated('analytic request'));
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to update analytic request!'));
      },
    },
  });

  const analyticRequestBulkStatusPutMutation = useMutation({
    mutationFn: async (data: {
      analyticRequests: APIModels.AnalyticRequest[];
      newStatus: AnalyticRequestStatus;
    }) => {
      const promises: Promise<APIClient.AnalyticRequest>[] = [];

      for (const analyticRequest of data.analyticRequests) {
        if (analyticRequest.id !== undefined) {
          const copy = cloneDeep(analyticRequest);

          delete copy.createdByProfile;
          delete copy.customerAnalytic;

          promises.push(
            APIClient.putAnalyticRequestById(analyticRequest.id, {
              ...copy,
              status: data.newStatus,
            })
          );
        }
      }

      await Promise.all(promises);
    },

    onSuccess: (_, variable) => {
      for (const analyticRequest of variable.analyticRequests) {
        APIUtils.updateInfiniteQueryCache({
          queryClient,
          queryKey: [APIUtils.QueryKey.analyticRequests],
          accessor: 'id',
          id: analyticRequest.id,
          data: { ...analyticRequest, status: variable.newStatus },
        });

        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: analyticRequest.id },
          ],
          data: { ...analyticRequest, status: variable.newStatus },
        });
      }

      toasts.add(toasts.prepare.entityUpdated('analytic requests'));
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to update analytic requests!'));
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.analyticRequests],
      });
    },
  });

  const analyticRequestBulkAvailabilityPutMutation = useMutation({
    mutationFn: async (data: {
      analyticRequests: APIModels.AnalyticRequest[];
      archived: boolean;
    }) => {
      const promises: Promise<APIClient.AnalyticRequest>[] = [];

      for (const analyticRequest of data.analyticRequests) {
        if (analyticRequest.id !== undefined) {
          const copy = cloneDeep(analyticRequest);

          delete copy.createdByProfile;
          delete copy.customerAnalytic;

          promises.push(
            APIClient.putAnalyticRequestById(analyticRequest.id, {
              ...copy,
              archived: data.archived,
            })
          );
        }
      }

      await Promise.all(promises);
    },
    onSuccess: (_, variable) => {
      for (const analyticRequest of variable.analyticRequests) {
        APIUtils.updateInfiniteQueryCache({
          queryClient,
          queryKey: [APIUtils.QueryKey.analyticRequests],
          accessor: 'id',
          id: analyticRequest.id,
          data: { ...analyticRequest, archived: variable.archived },
        });

        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: analyticRequest.id },
          ],
          data: { ...analyticRequest, archived: variable.archived },
        });
      }

      if (variable.archived === true) {
        toasts.add(toasts.prepare.entityArchived('analytic requests'));
      } else {
        toasts.add(toasts.prepare.entityRestored('analytic requests'));
      }
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to update analytic requests!'));
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.analyticRequests],
      });
    },
  });

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
    },
  });

  const usersLookupTable = useLookupTable(usersQuery.data, 'id');

  const analyticRequestCaptures = useQuery({
    queryKey: [
      APIUtils.QueryKey.analyticRequests,
      { analyticRequestId: Number(analyticRequestId) },
      APIUtils.QueryKey.captures,
    ],
    queryFn: async () => {
      const promises: Promise<APIClient.Capture>[] = [];

      for (const id of analyticRequestQuery.data?.captureIds ?? []) {
        promises.push(APIClient.getCaptureById(id));
      }

      return Promise.all(promises);
    },
    enabled:
      Number.isSafeInteger(Number(analyticRequestId)) &&
      analyticRequestQuery.isSuccess &&
      analyticRequestQuery.data.captureIds !== null,
  });

  const analyticRequestJobsQuery =
    APIClient.useGetAnalyticRequestJobsByAnalyticRequestId(
      Number(analyticRequestId),
      {
        query: {
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.analyticRequestJobs,
          ],
          enabled: Number.isSafeInteger(Number(analyticRequestId)),
        },
      }
    );
  useEffect(() => {
    if (analyticRequestId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(analyticRequestId))) {
      if (isViteApp) {
        navigate('/app/admin/operations/analytic-requests' + params);
      } else {
        navigate('/ops/analytics' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: Number(analyticRequestId) },
        ],
      });
    }
  }, [analyticRequestId]);

  const analyticRequestAvailabilityPutMutation = useMutation({
    mutationFn: async (data: {
      analyticRequest: APIModels.AnalyticRequest;
      archived: boolean;
    }) => {
      const copy = cloneDeep(data);

      delete copy.analyticRequest.createdByProfile;
      delete copy.analyticRequest.customerAnalytic;

      return APIClient.putAnalyticRequestById(
        copy.analyticRequest.id as number,
        {
          ...copy.analyticRequest,
          archived: data.archived,
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.analyticRequests],
      });
    },
    onSuccess: (_, variable) => {
      APIUtils.updateInfiniteQueryCache({
        queryClient,
        queryKey: [APIUtils.QueryKey.analyticRequests],
        accessor: 'id',
        id: variable.analyticRequest.id,
        data: {
          ...variable.analyticRequest,
          archived: variable.archived,
        },
      });

      APIUtils.updateQueryCache({
        queryClient,
        queryKey: [
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: variable.analyticRequest.id },
        ],
        data: {
          ...variable.analyticRequest,
          archived: variable.archived,
        },
      });

      if (variable.archived === true) {
        toasts.add(toasts.prepare.entityArchived('analytic request'));
      } else {
        toasts.add(toasts.prepare.entityRestored('analytic request'));
      }
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to update analytic request!'));
    },
  });

  const mosaicEnginesQuery = APIClient.useGetMosaicEngines({
    query: {
      queryKey: [APIUtils.QueryKey.mosaicEngines, { archived: false }],
      select: (data) =>
        APIUtils.Sort.mosaicEngines(data.filter((d) => d.archived === false)),
    },
  });

  const mlModelsQuery = APIClient.useGetMlModels({
    query: {
      queryKey: [APIUtils.QueryKey.mlModels, { archived: false }],
      select: (data) =>
        APIUtils.Sort.mlModels(data.filter((d) => d.archived === false)),
    },
  });

  const pipelinesQuery = APIClient.useGetPipelines({
    query: {
      queryKey: [APIUtils.QueryKey.pipelines],
      select: (data) => APIUtils.Sort.pipelines(data),
    },
  });

  const adHocJobPostMutation = useMutation({
    mutationFn: async (data: {
      category: JobTypeCategory;
      extractionJob?: APIModels.CaptureExtractionJob;
      captureJob?: APIModels.CaptureJob;
      pipelineJob?: APIModels.PipelineJob;
    }) => {
      const { category, extractionJob, captureJob, pipelineJob } = data;

      if (category === JobTypeCategory.CaptureExtractionJob && !extractionJob) {
        throw new Error(
          'Extraction job is required for capture extraction job!'
        );
      } else if (category === JobTypeCategory.CaptureJob && !captureJob) {
        throw new Error('Capture job is required for capture job!');
      } else if (category === JobTypeCategory.PipelineJob && !pipelineJob) {
        throw new Error('Pipeline job is required for pipeline job!');
      }

      if (category === JobTypeCategory.CaptureExtractionJob) {
        await APIClient.submitNewExtractionJobToPipeline(
          extractionJob as APIClient.CaptureExtractionJob
        );
      } else if (category === JobTypeCategory.CaptureJob) {
        await APIClient.submitCaptureJobToPipeline(
          captureJob as APIClient.CaptureJob
        );
      } else if (category === JobTypeCategory.PipelineJob) {
        await APIClient.submitPipelineJob(pipelineJob as APIClient.PipelineJob);
      } else {
        throw new Error('Job type category not supported yet!');
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: Number(analyticRequestId) },
          APIUtils.QueryKey.analyticRequestJobs,
        ],
      });
    },
    onSuccess: () => {
      toasts.add(toasts.prepare.entityCreated('ad-hoc analytic job'));

      if (isViteApp) {
        navigate(
          `/app/admin/operations/analytic-requests/${analyticRequestId}/details`
        );
      } else {
        navigate(`/ops/analytics/${analyticRequestId}/details`);
      }
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to create ad-hoc analytic job!'));
    },
  });

  // using extraction job status for all jobs for now
  // (capture job and pipeline jobs are just strings in the db)
  const extractionJobStatusesQuery = APIClient.useGetCaptureExtractionJobStatus(
    {
      query: {
        queryKey: [APIUtils.QueryKey.captureExtractionJobStatuses],
        select: (data) => APIUtils.Sort.captureExtractionJobStatuses(data),
      },
    }
  );

  const extractionJobByIdQuery = APIClient.useGetCaptureExtractionJobsById(
    Number(extractionJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.extractionJobs,
          { extractionJobId: Number(extractionJobId) },
        ],
        enabled: Number.isSafeInteger(Number(extractionJobId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const captureJobByIdQuery = APIClient.useGetCaptureJobById(
    Number(captureJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(captureJobId) },
        ],
        enabled: Number.isSafeInteger(Number(captureJobId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const pipelineJobByIdQuery = APIClient.useGetPipelineJobById(
    Number(pipelineJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.pipelineJobs,
          { pipelineJobId: Number(pipelineJobId) },
        ],
        enabled: Number.isSafeInteger(Number(pipelineJobId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const operationJobByIdQuery = APIClient.useGetOperationJobById(
    Number(operationJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.operationJobs,
          { operationJobId: Number(operationJobId) },
        ],
        enabled: Number.isSafeInteger(Number(operationJobId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const captureJobPutMutation = APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
        });

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(variables.jobId) },
          ],
        });

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.analyticRequestJobs,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(variables.jobId) },
          ],
          data: variables.data,
        });

        APIUtils.updateListQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
          data: variables.data,
          accessor: 'id',
          id: variables.jobId,
        });

        toasts.add(toasts.prepare.entityUpdated('capture job'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update capture job!'));
      },
    },
  });

  const extractionJobPutMutation = APIClient.usePutCaptureExtractionJobById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.extractionJobs,
            { extractionJobId: Number(variables.id) },
          ],
        });

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.analyticRequestJobs,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.extractionJobs,
            { extractionJobId: Number(variables.id) },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('extraction job'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update extraction job!'));
      },
    },
  });

  const pipelineJobPutMutation = APIClient.usePutPipelineJobById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.pipelineJobs,
            { pipelineJobId: Number(variables.id) },
          ],
        });

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.analyticRequestJobs,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.pipelineJobs,
            { pipelineJobId: Number(variables.id) },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('pipeline job'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update pipeline job!'));
      },
    },
  });

  const operationJobPutMutation = APIClient.usePutOperationJobById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.operationJobs,
            { operationJobId: Number(variables.id) },
          ],
        });

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.analyticRequestJobs,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.operationJobs,
            { operationJobId: Number(variables.id) },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('operation job'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update operation job!'));
      },
    },
  });

  const captureJobsByCaptureIdQuery = APIClient.useGetCaptureJobsByCaptureId(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureJobs,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  return {
    organizationsQuery,
    organizationsLookupTable,
    analyticRequestsQuery,
    analyticsQuery,
    analyticsLookupTable,
    analyticRequestPutMutation,
    analyticRequestBulkStatusPutMutation,
    analyticRequestBulkAvailabilityPutMutation,
    analyticRequestQuery,
    usersQuery,
    usersLookupTable,
    hasAnalyticRequestManagementPermission,
    analyticRequestCaptures,
    analyticRequestJobsQuery,
    analyticRequestAvailabilityPutMutation,
    mosaicEnginesQuery,
    mlModelsQuery,
    pipelinesQuery,
    adHocJobPostMutation,
    extractionJobStatusesQuery,
    extractionJobByIdQuery,
    captureJobByIdQuery,
    pipelineJobByIdQuery,
    operationJobByIdQuery,
    captureJobPutMutation,
    extractionJobPutMutation,
    pipelineJobPutMutation,
    operationJobPutMutation,
    captureJobsByCaptureIdQuery,
  };
};
