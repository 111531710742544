/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type {
  AnalyticJob,
  AnalyticRequest,
  AnalyticRequestFilter,
  GetAnalyticRequestsByCaptureIdParams,
} from '.././models';

type IsAny<T> = 0 extends 1 & T ? true : false;
type IsUnknown<T> = IsAny<T> extends true
  ? false
  : unknown extends T
  ? true
  : false;
type Primitive = string | number | boolean | bigint | symbol | undefined | null;
type isBuiltin = Primitive | Function | Date | Error | RegExp;
type NonReadonly<T> = T extends Exclude<isBuiltin, Error>
  ? T
  : T extends Map<infer Key, infer Value>
  ? Map<NonReadonly<Key>, NonReadonly<Value>>
  : T extends ReadonlyMap<infer Key, infer Value>
  ? Map<NonReadonly<Key>, NonReadonly<Value>>
  : T extends WeakMap<infer Key, infer Value>
  ? WeakMap<NonReadonly<Key>, NonReadonly<Value>>
  : T extends Set<infer Values>
  ? Set<NonReadonly<Values>>
  : T extends ReadonlySet<infer Values>
  ? Set<NonReadonly<Values>>
  : T extends WeakSet<infer Values>
  ? WeakSet<NonReadonly<Values>>
  : T extends Promise<infer Value>
  ? Promise<NonReadonly<Value>>
  : T extends {}
  ? { -readonly [Key in keyof T]: NonReadonly<T[Key]> }
  : IsUnknown<T> extends true
  ? unknown
  : T;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAnalyticRequest = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticRequest[]>(
    { url: `/api/AnalyticRequests`, method: 'GET', signal },
    options
  );
};

export const getGetAnalyticRequestQueryKey = () => {
  return [`/api/AnalyticRequests`] as const;
};

export const getGetAnalyticRequestQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticRequest>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAnalyticRequest>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticRequestQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticRequest>>
  > = ({ signal }) => getAnalyticRequest(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticRequest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticRequestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticRequest>>
>;
export type GetAnalyticRequestQueryError = ErrorType<void>;

export const useGetAnalyticRequest = <
  TData = Awaited<ReturnType<typeof getAnalyticRequest>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAnalyticRequest>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticRequestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postAnalyticRequest = (
  analyticRequest: BodyType<AnalyticRequest>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/AnalyticRequests`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: analyticRequest,
    },
    options
  );
};

export const getPostAnalyticRequestMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAnalyticRequest>>,
    TError,
    { data: BodyType<AnalyticRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAnalyticRequest>>,
  TError,
  { data: BodyType<AnalyticRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAnalyticRequest>>,
    { data: BodyType<AnalyticRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return postAnalyticRequest(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAnalyticRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAnalyticRequest>>
>;
export type PostAnalyticRequestMutationBody = BodyType<AnalyticRequest>;
export type PostAnalyticRequestMutationError = ErrorType<void>;

export const usePostAnalyticRequest = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAnalyticRequest>>,
    TError,
    { data: BodyType<AnalyticRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAnalyticRequest>>,
  TError,
  { data: BodyType<AnalyticRequest> },
  TContext
> => {
  const mutationOptions = getPostAnalyticRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFilteredAnalyticRequests = (
  skip: number,
  take: number,
  analyticRequestFilter: BodyType<NonReadonly<AnalyticRequestFilter>>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<AnalyticRequest[]>(
    {
      url: `/api/AnalyticRequests/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: analyticRequestFilter,
    },
    options
  );
};

export const getGetFilteredAnalyticRequestsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredAnalyticRequests>>,
    TError,
    {
      skip: number;
      take: number;
      data: BodyType<NonReadonly<AnalyticRequestFilter>>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredAnalyticRequests>>,
  TError,
  {
    skip: number;
    take: number;
    data: BodyType<NonReadonly<AnalyticRequestFilter>>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredAnalyticRequests>>,
    {
      skip: number;
      take: number;
      data: BodyType<NonReadonly<AnalyticRequestFilter>>;
    }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredAnalyticRequests(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredAnalyticRequestsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredAnalyticRequests>>
>;
export type GetFilteredAnalyticRequestsMutationBody = BodyType<
  NonReadonly<AnalyticRequestFilter>
>;
export type GetFilteredAnalyticRequestsMutationError = ErrorType<void>;

export const useGetFilteredAnalyticRequests = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredAnalyticRequests>>,
    TError,
    {
      skip: number;
      take: number;
      data: BodyType<NonReadonly<AnalyticRequestFilter>>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredAnalyticRequests>>,
  TError,
  {
    skip: number;
    take: number;
    data: BodyType<NonReadonly<AnalyticRequestFilter>>;
  },
  TContext
> => {
  const mutationOptions =
    getGetFilteredAnalyticRequestsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAnalyticRequestById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticRequest>(
    {
      url: `/api/AnalyticRequests/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetAnalyticRequestByIdQueryKey = (id: number) => {
  return [`/api/AnalyticRequests/${id}`] as const;
};

export const getGetAnalyticRequestByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAnalyticRequestByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticRequestById>>
  > = ({ signal }) => getAnalyticRequestById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticRequestById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticRequestByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticRequestById>>
>;
export type GetAnalyticRequestByIdQueryError = ErrorType<void>;

export const useGetAnalyticRequestById = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticRequestByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putAnalyticRequestById = (
  id: number,
  analyticRequest: BodyType<AnalyticRequest>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<AnalyticRequest>(
    {
      url: `/api/AnalyticRequests/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: analyticRequest,
    },
    options
  );
};

export const getPutAnalyticRequestByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAnalyticRequestById>>,
    TError,
    { id: number; data: BodyType<AnalyticRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putAnalyticRequestById>>,
  TError,
  { id: number; data: BodyType<AnalyticRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putAnalyticRequestById>>,
    { id: number; data: BodyType<AnalyticRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putAnalyticRequestById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutAnalyticRequestByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putAnalyticRequestById>>
>;
export type PutAnalyticRequestByIdMutationBody = BodyType<AnalyticRequest>;
export type PutAnalyticRequestByIdMutationError = ErrorType<void>;

export const usePutAnalyticRequestById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAnalyticRequestById>>,
    TError,
    { id: number; data: BodyType<AnalyticRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putAnalyticRequestById>>,
  TError,
  { id: number; data: BodyType<AnalyticRequest> },
  TContext
> => {
  const mutationOptions = getPutAnalyticRequestByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAnalyticRequestsByCaptureId = (
  id: number,
  params?: GetAnalyticRequestsByCaptureIdParams,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticRequest[]>(
    {
      url: `/api/AnalyticRequests/Capture/${encodeURIComponent(String(id))}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetAnalyticRequestsByCaptureIdQueryKey = (
  id: number,
  params?: GetAnalyticRequestsByCaptureIdParams
) => {
  return [
    `/api/AnalyticRequests/Capture/${id}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAnalyticRequestsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetAnalyticRequestsByCaptureIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAnalyticRequestsByCaptureIdQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>
  > = ({ signal }) =>
    getAnalyticRequestsByCaptureId(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticRequestsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>
>;
export type GetAnalyticRequestsByCaptureIdQueryError = ErrorType<void>;

export const useGetAnalyticRequestsByCaptureId = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetAnalyticRequestsByCaptureIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticRequestsByCaptureIdQueryOptions(
    id,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAnalyticRequestJobsByAnalyticRequestId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticJob[]>(
    {
      url: `/api/AnalyticRequests/${encodeURIComponent(
        String(id)
      )}/AnalyticJobs`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetAnalyticRequestJobsByAnalyticRequestIdQueryKey = (
  id: number
) => {
  return [`/api/AnalyticRequests/${id}/AnalyticJobs`] as const;
};

export const getGetAnalyticRequestJobsByAnalyticRequestIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAnalyticRequestJobsByAnalyticRequestIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>
  > = ({ signal }) =>
    getAnalyticRequestJobsByAnalyticRequestId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticRequestJobsByAnalyticRequestIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>
>;
export type GetAnalyticRequestJobsByAnalyticRequestIdQueryError =
  ErrorType<void>;

export const useGetAnalyticRequestJobsByAnalyticRequestId = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestJobsByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticRequestJobsByAnalyticRequestIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAnalyticRequestBySharedCaptureUuid = (
  uuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticRequest[]>(
    {
      url: `/api/AnalyticRequests/shared/CaptureUuid/${encodeURIComponent(
        String(uuid)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetAnalyticRequestBySharedCaptureUuidQueryKey = (
  uuid: string
) => {
  return [`/api/AnalyticRequests/shared/CaptureUuid/${uuid}`] as const;
};

export const getGetAnalyticRequestBySharedCaptureUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAnalyticRequestBySharedCaptureUuidQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>
  > = ({ signal }) =>
    getAnalyticRequestBySharedCaptureUuid(uuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticRequestBySharedCaptureUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>
>;
export type GetAnalyticRequestBySharedCaptureUuidQueryError = ErrorType<void>;

export const useGetAnalyticRequestBySharedCaptureUuid = <
  TData = Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticRequestBySharedCaptureUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticRequestBySharedCaptureUuidQueryOptions(
    uuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
