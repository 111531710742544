import { faGalleryThumbnails } from '@fortawesome/pro-light-svg-icons';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'oidc-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UseGetReturn } from 'restful-react';
import compare from 'trivial-compare';

import {
  APIClient,
  APIUtils,
  Capture,
  CaptureJob,
  useDeleteCapture,
  useGetCaptureImagesByCaptureId,
  useGetCaptureImagesByCaptureJobId,
  usePutCaptureById,
  usePutCaptureImageById,
} from '@agerpoint/api';
import {
  DialogModal,
  DraggableWindow,
  GalleryEvents,
} from '@agerpoint/component';
import {
  AppBarProps,
  CaptureExpansionPanels,
  CaptureImage,
  CapturesViewerImage,
  EventBusNames,
  IViewer,
  ImageSelection,
  ViewerTypeOld,
} from '@agerpoint/types';
import {
  eventBus,
  useGlobalStore,
  usePageTitle,
  useRecentAccess,
  useToasts,
} from '@agerpoint/utilities';

import {
  CaptureViewerBottomPanelImageGallery,
  CaptureViewerImageSelectionIconBar,
  CaptureViewerImageSelectionTable,
  CaptureViewerImageSelectionTableReadOnly,
  PotreeControlsFloatComponent,
  Sidebar,
} from '..';
import { BottomPanel } from '../bottom-panel/bottom-panel';
import { CapturesViewerExpansionPanel } from '../captures-expansion/captures-expansion';
import { CapturesJobExpansionPanel } from '../captures-expansion/captures-job-expansion';
import { ManageCaptureWrapper } from '../captures-shared/capture-details-modal/manage-capture-wrapper';
import { ThreeDViewer } from '../three-d-wrapper';
import { CaptureObjectsTableAndModal } from './capture-objects-table-and-modal/capture-objects-table-and-modal';
import { CapturesViewerExpandedImageViewer } from './capture-viewer-expanded-image-viewer/capture-viewer-expanded-image-viewer';
import { CaptureViewerImageSelectionPipeline } from './capture-viewer-gallery/capture-viewer-pipeline';
import { CapturesViewerSidebarContentBody } from './captures-viewer-sidebar-content/captures-viewer-sidebar-content';

interface CapturesViewerProps {
  appBarProps: AppBarProps;
}

export const CapturesViewer = ({ appBarProps }: CapturesViewerProps) => {
  const {
    sidebar,
    capturesViewer: {
      captureImages,
      viewerType,
      actions: { setCaptureImages },
    },
  } = useGlobalStore();
  const navigate = useNavigate();
  const { userData } = useAuth();

  const toasts = useToasts();

  const recentAccess = useRecentAccess();

  const { captureId, eptId } = useParams();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
    },
  });

  useEffect(() => {
    if (!captureQuery.data?.id) return;
    recentAccess.add({
      id: `capture-${captureQuery.data?.id}`,
      dataType: 'capture',
      name: captureQuery.data?.captureName ?? 'Unknown',
      path: `/captures/${captureId}`,
    });
  }, [captureQuery.data]);

  const [captureJob, setCaptureJob] = useState<CaptureJob>();

  const {
    data: captureImagesData,
    refetch: refetchCaptureImagesData,
    error: captureImagesError,
    loading: captureImagesLoading,
  } = useGetCaptureImagesByCaptureId({
    captureId: Number(captureId) ?? NaN,
    lazy: true,
  });

  const {
    data: captureJobImagesData,
    refetch: refetchCaptureJobImagesData,
    error: captureJobImagesError,
    loading: captureJobImagesLoading,
  } = useGetCaptureImagesByCaptureJobId({
    captureJobId: captureJob?.id ?? NaN,
    lazy: true,
  }) as unknown as UseGetReturn<CaptureImage[], void, void, unknown>;

  const [apiImages, setApiImages] = useState<CaptureImage[] | null>(null);

  const apiImagesLoading = useMemo(() => {
    return captureJobImagesLoading || captureImagesLoading;
  }, [captureImagesLoading, captureJobImagesLoading]);

  const apiImagesError = useMemo(() => {
    return captureJobImagesError ?? captureImagesError;
  }, [captureImagesError, captureJobImagesError]);

  useEffect(() => {
    if (captureJobImagesData && captureJobImagesData.length !== 0) {
      setApiImages(captureJobImagesData);
    } else if (
      captureJobImagesError ||
      (captureJobImagesData && captureJobImagesData.length === 0)
    ) {
      refetchCaptureImagesData();
    }
    return () => {
      setApiImages(null);
      setCaptureImages([]);
    };
  }, [captureJobImagesData, captureJobImagesError]);

  useEffect(() => {
    if (!captureJob?.id) return;
    refetchCaptureJobImagesData();
  }, [captureJob?.id]);

  useEffect(() => {
    setApiImages(captureImagesData as CaptureImage[]);
  }, [captureImagesData]);

  useEffect(() => {
    sidebar.actions.setIsOpen(true);
  }, []);

  const { mutate: putImage } = usePutCaptureImageById({ id: NaN });
  const { mutate: deleteCapture } = useDeleteCapture({});
  const { mutate: putCapture } = usePutCaptureById({
    id: NaN,
  });
  const [expandedImage, setExpandedImage] = useState<CapturesViewerImage>();
  const [isBottomPanelOpen, setIsBottomPanelOpen] = useState(true);
  const [isBottomPanelExpanded, setIsBottomPanelExpanded] = useState(false);
  const [isQAQCModeEnabled, setIsQAQCModeEnabled] = useState(false);
  const [qaqcModeShowSelectedOnly, setQAQCModeShowSelectedOnly] =
    useState(false);
  const [highlightedImage, setHighlightedImage] = useState<number>();
  const [viewer, setViewer] =
    useState<React.MutableRefObject<IViewer | undefined>>();
  const [is3dLayersOpen] = useState(true);
  const [isExpansionPanelOpen, setIsExpansionPanelOpen] = useState(false);
  const [isCaptureAtributesOpen] = useState(false);
  const [whichExpansionPanel, setWhichExpansionPanel] =
    useState<CaptureExpansionPanels>(CaptureExpansionPanels.Unknown);
  const [selectedCaptureImages, setSelectedCaptureImages] = useState<
    CapturesViewerImage[]
  >([]);

  const [imageSelectionMode, setImageSelectionMode] =
    useState<ImageSelection>();
  const [showDetailModal, setShowDetailModal] = useState(false);

  const apiImagesErrorShown = useRef(false);
  useEffect(() => {
    if (apiImagesError && isBottomPanelOpen && !apiImagesErrorShown.current) {
      apiImagesErrorShown.current = true;
      toasts.add(toasts.prepare.error('Failed to load images!'));
    }
  }, [apiImagesError, isBottomPanelOpen]);

  useEffect(() => {
    // find completed job
    if (captureQuery.data && captureQuery.data.completedJobs?.length) {
      const completedJob = captureQuery.data.completedJobs.find(
        (job) => job.eptPointcloudId === Number(eptId)
      );
      if (!completedJob) {
        return;
      }

      setCaptureJob(completedJob);
    }
  }, [captureQuery.data, eptId]);

  const imageSelectedCallback = useCallback(
    async (image: CapturesViewerImage) => {
      if (imageSelectionMode?.mode) {
        const alreadySelected = selectedCaptureImages.find(
          (img) => img.id === image.id
        );
        if (alreadySelected) {
          toasts.add({
            title: 'This image is already selected.',
            type: 'info',
          });
          return;
        }
      }

      if (imageSelectionMode?.mode === 'ADD') {
        image.isFavorite = true;
        putImage(image, { pathParams: { id: image.id } }).catch((e) => {
          console.error(e);
          toasts.add(
            toasts.prepare.error(
              'Failed to synchronize image selection, please refresh the page!'
            )
          );
        });
        setSelectedCaptureImages((prev) => [...prev, image]);
        setImageSelectionMode({});
      } else if (
        imageSelectionMode?.mode === 'EDIT' &&
        imageSelectionMode?.editImageId
      ) {
        const copy = [...selectedCaptureImages];
        const index = copy.findIndex(
          (image) => image.id === imageSelectionMode.editImageId
        );
        if (index > -1) {
          image.note = copy[index].note;
          image.isFavorite = true;
          copy[index].note = undefined;
          copy[index].isFavorite = false;

          const newImage = { ...image };
          const oldImage = { ...copy[index] };

          putImage(oldImage, { pathParams: { id: oldImage.id } })
            .then(() => {
              putImage(newImage, { pathParams: { id: newImage.id } }).catch(
                (e) => {
                  toasts.add(
                    toasts.prepare.error(
                      'Failed to synchronize image selection, please refresh the page!'
                    )
                  );
                }
              );
            })
            .catch((e) => {
              toasts.add(
                toasts.prepare.error(
                  'Failed to synchronize image selection, please refresh the page!'
                )
              );
            });

          copy[index] = image;
        }
        setSelectedCaptureImages(copy);
        setImageSelectionMode({});
      }
    },
    [imageSelectionMode, selectedCaptureImages]
  );

  useEffect(() => {
    if (!isQAQCModeEnabled) {
      setImageSelectionMode({});
      setQAQCModeShowSelectedOnly(false);
    }
  }, [isQAQCModeEnabled]);

  useEffect(() => {
    if (!isBottomPanelExpanded) {
      setIsQAQCModeEnabled(false);
    }
  }, [isBottomPanelExpanded]);

  useEffect(() => {
    if (!isBottomPanelOpen) {
      setIsQAQCModeEnabled(false);
      setIsBottomPanelExpanded(false);
    }
  }, [isBottomPanelOpen]);

  useEffect(() => {
    if (viewerType === ViewerTypeOld.THREE_PLY) {
      setIsBottomPanelOpen(false);
    } else {
      setIsBottomPanelOpen(true);
    }
  }, [viewerType]);

  const toggleSidebar = useCallback(() => {
    sidebar.actions.setIsOpen(!sidebar.isOpen);
  }, [sidebar]);

  const toggleBottomPanel = useCallback(() => {
    setIsBottomPanelOpen((prev) => !prev);
  }, []);

  const openExpansionPanel = useCallback(
    (whichPanel: CaptureExpansionPanels) => {
      setIsExpansionPanelOpen((prev) => !prev);
      setWhichExpansionPanel(whichPanel);
    },
    []
  );

  const handleCameraPositionClicked = useCallback(
    (data: CustomEvent) => {
      if (!isBottomPanelOpen) {
        setIsBottomPanelOpen(true);
      }
      const index = captureImages.findIndex((i) => i.id === +data.detail.id);
      if (index > -1) {
        setHighlightedImage(captureImages[index].id);
        window.dispatchEvent(
          new CustomEvent(GalleryEvents.scrollTo, { detail: { index } })
        );
      }
    },
    [captureImages, isBottomPanelOpen]
  );

  const handleImageCarouselClicked = useCallback(
    (e: CustomEvent) => {
      if (!isQAQCModeEnabled) {
        setHighlightedImage(e.detail.id);
      }
    },
    [isQAQCModeEnabled]
  );

  const captureArchive = async (c: Capture) => {
    if (!c.id) {
      return;
    }
    try {
      await deleteCapture(c.id);

      toasts.add(toasts.prepare.entityArchived('capture'));
    } catch (e) {
      toasts.add(toasts.prepare.error('Failed to archive capture!'));
    }
  };

  const unarchiveCapture = async (c: Capture) => {
    if (!c.id) {
      return;
    }
    try {
      const updatedCapture = { ...captureQuery.data, isArchived: false };
      await putCapture(updatedCapture, { pathParams: { id: c.id } });
      toasts.add(toasts.prepare.entityRestored('capture'));
    } catch (e) {
      toasts.add(toasts.prepare.error('Failed to restore capture!'));
    }
  };

  usePageTitle(() => {
    if (captureQuery.data?.captureName) {
      return `${captureQuery.data.captureName}`;
    }
    return;
  }, [captureQuery.data]);

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.Point3dLocationMarkerClicked,
      handleCameraPositionClicked,
      true
    );

    const eventId2 = eventBus.on(
      EventBusNames.ImageCarouselImageClicked,
      handleImageCarouselClicked,
      true
    );

    return () => {
      eventBus.remove(
        EventBusNames.Point3dLocationMarkerClicked,
        handleCameraPositionClicked,
        eventId
      );

      eventBus.remove(
        EventBusNames.ImageCarouselImageClicked,
        handleImageCarouselClicked,
        eventId2
      );
    };
  }, [handleCameraPositionClicked, handleImageCarouselClicked]);

  useEffect(() => {
    if (captureQuery.data && captureQuery.data.completedJobs?.length) {
      const completedJob = captureQuery.data.completedJobs.find(
        (job) => job.eptPointcloudId === Number(eptId)
      );
      if (!completedJob) {
        navigate('/captures');
      }
    }
  }, [captureQuery.data, eptId]);

  useEffect(() => {
    if (apiImages === null) return;
    let images = [...apiImages] as unknown as CapturesViewerImage[];
    images = images.sort((a, b) => compare(a.id, b.id));
    const selectedApiImages = [];
    for (let i = 0; i < images.length; i++) {
      images[i].localIndex = i + 1;
      if (images[i].isFavorite) {
        selectedApiImages.push(images[i]);
      }
    }
    setCaptureImages(images);
    setSelectedCaptureImages(selectedApiImages);
  }, [apiImages]);

  return (
    <>
      <CapturesViewerExpandedImageViewer
        expandedImage={expandedImage}
        setExpandedImage={setExpandedImage}
      />
      <CaptureObjectsTableAndModal
        viewer={viewer}
        captureId={Number(captureId)}
      />

      <DraggableWindow
        title="Capture Image Selection"
        show={isQAQCModeEnabled}
        height={224}
        width={450}
        toggleShow={() => {
          setIsQAQCModeEnabled((prev) => !prev);
        }}
        leftInitial={324}
        topInitial={window.innerHeight - 229}
      >
        <CaptureViewerImageSelectionTable
          selectedCaptureImages={selectedCaptureImages}
          imageSelectionMode={imageSelectionMode}
          qaqcModeShowSelectedOnly={qaqcModeShowSelectedOnly}
          setQAQCModeShowSelectedOnly={setQAQCModeShowSelectedOnly}
          setImageSelectionMode={setImageSelectionMode}
          setSelectedCaptureImages={setSelectedCaptureImages}
        />
      </DraggableWindow>
      <Sidebar
        expansionOpen={isExpansionPanelOpen}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={sidebar.isOpen}
      >
        {captureQuery.data !== null && captureQuery.data?.id ? (
          <CapturesViewerSidebarContentBody
            captureData={captureQuery.data}
            appBarProps={appBarProps}
            viewer={viewer}
            eptId={Number(eptId)}
            show3dLayers={is3dLayersOpen}
            openExpansionPanel={openExpansionPanel}
            showCaptureAtributes={isCaptureAtributesOpen}
          />
        ) : null}
        {captureQuery.data?.id
          ? whichExpansionPanelFn({
              capture: captureQuery.data,
              whichExpansionPanel,
              openExpansionPanel,
              isExpansionPanelOpen,
              isSidebarOpen: sidebar.isOpen,
            })
          : null}
      </Sidebar>
      <BottomPanel
        sidebarOpen={sidebar.isOpen}
        expansionOpen={false}
        open={isBottomPanelOpen}
        customHeight={isBottomPanelExpanded ? 'h-screen' : 'h-44'}
      >
        <div className="flex flex-row w-full h-full justify-end overflow-hidden">
          {captureId && captureImages?.length && isBottomPanelOpen ? (
            <CaptureViewerBottomPanelImageGallery
              captureImages={captureImages}
              selectedCaptureImages={selectedCaptureImages}
              imageSelectionMode={imageSelectionMode}
              imageSelectedCallback={imageSelectedCallback}
              isBottomPanelExpanded={isBottomPanelExpanded}
              setExpandedImage={setExpandedImage}
              isQAQCModeEnabled={isQAQCModeEnabled}
              qaqcModeShowSelectedOnly={qaqcModeShowSelectedOnly}
              highlightedImage={highlightedImage}
            />
          ) : null}
          {apiImagesLoading && isBottomPanelOpen && (
            <div className="w-full h-full flex justify-center items-center">
              <FontAwesomeIcon icon={faSpinner} spin className="w-10 h-10" />
            </div>
          )}
          {!apiImagesLoading && isBottomPanelOpen && !captureImages.length && (
            <div className="w-full h-full flex justify-center items-center text-gray">
              No Images
            </div>
          )}
          <CaptureViewerImageSelectionIconBar
            isBottomPanelOpen={isBottomPanelOpen}
            toggleBottomPanel={toggleBottomPanel}
            isBottomPanelExpanded={isBottomPanelExpanded}
            setIsQAQCModeEnabled={setIsQAQCModeEnabled}
            setIsBottomPanelExpanded={setIsBottomPanelExpanded}
            isQAQCModeEnabled={isQAQCModeEnabled}
            setShowDetailModal={setShowDetailModal}
          />
        </div>
      </BottomPanel>
      {viewerType === ViewerTypeOld.POTREE && (
        <PotreeControlsFloatComponent
          viewer={viewer}
          captureId={Number(captureId)}
          size="default"
        />
      )}

      <div
        className={`absolute bottom-0 w-full h-8 mb-4 pr-4 flex justify-end transition-opacity duration-300
          ${isBottomPanelOpen ? 'opacity-0' : 'opacity-100'}
        `}
      >
        <div
          className={`flex flex-row justify-center items-center gap-2 w-8 rounded z-50 bg-white cursor-pointer hover:bg-gray-200`}
          onClick={() => setIsBottomPanelOpen((prev) => !prev)}
        >
          <FontAwesomeIcon icon={faGalleryThumbnails} />
        </div>
      </div>

      <ThreeDViewer
        setViewer={setViewer}
        token={userData?.access_token || ''}
      />

      <DialogModal
        open={showDetailModal}
        title="Image Selection Details And Processing"
        handleCloseDialog={() => setShowDetailModal(false)}
        size="large"
      >
        <div className="w-full p-1">
          <h5 className="text-xl font-bold pt-6">Processing Details</h5>
          <CaptureViewerImageSelectionPipeline captureId={Number(captureId)} />
          <h5 className="text-xl font-bold pt-6">Image Selection</h5>

          <CaptureViewerImageSelectionTableReadOnly
            selectedCaptureImages={selectedCaptureImages}
          />
        </div>
      </DialogModal>
      <ManageCaptureWrapper
        archiveCapture={captureArchive}
        unarchiveCapture={unarchiveCapture}
      />
    </>
  );
};

const whichExpansionPanelFn = ({
  capture,
  whichExpansionPanel,
  openExpansionPanel,
  isExpansionPanelOpen,
  isSidebarOpen,
}: {
  capture: Capture;
  whichExpansionPanel: CaptureExpansionPanels;
  openExpansionPanel: (whichPanel: CaptureExpansionPanels) => void;
  isExpansionPanelOpen: boolean;
  isSidebarOpen: boolean;
}) => {
  switch (whichExpansionPanel) {
    case CaptureExpansionPanels.CaptureJobInfo:
      return (
        <CapturesViewerExpansionPanel
          capture={capture}
          openExpansionPanel={openExpansionPanel}
          isExpansionPanelOpen={isExpansionPanelOpen}
          isSidebarOpen={isSidebarOpen}
        />
      );
    case CaptureExpansionPanels.CaptureColorSelector:
      return (
        <CapturesJobExpansionPanel
          isSidebarOpen={isSidebarOpen}
          isExpansionPanelOpen={isExpansionPanelOpen}
          capture={capture}
          openExpansionPanel={openExpansionPanel}
        />
      );
    default:
      return null;
  }
};
