import { BehaviorSubject } from 'rxjs';
import {
  AmbientLight,
  DirectionalLight,
  HemisphereLight,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  Annotation3dPoints,
  CameraState,
  ColorsThreeD,
  EventBusData,
  EventBusNames,
  FrameEvent,
  IGsThreeDViewer,
  LatLngAlt,
} from '@agerpoint/types';
import { eventBus } from '@agerpoint/utilities';

import { Annotations3d } from '../annotations';
import { BaseThreeDViewer } from '../base-viewer/BaseThreeDViewer';

export class GsThreeDViewer
  extends BaseThreeDViewer
  implements IGsThreeDViewer
{
  private eventBusLookup: { [key: string]: any } = {};
  private _gs3dFrameEvent = new BehaviorSubject<FrameEvent | null>(null);

  constructor(
    camera: PerspectiveCamera,
    scene: Scene,
    controls: OrbitControls,
    renderer: WebGLRenderer,
    targetEl: HTMLElement,
    isReadOnly = false
  ) {
    console.info('Gs3d Viewer Initialized');

    super(true, targetEl);
    this.camera = camera;
    this.scene = scene;
    this.controls = controls;
    this.renderer = renderer;
    this.camera.far = 1000;
    this.camera.near = 0.0001;
    this.annotations3d = new Annotations3d(
      scene,
      camera,
      false,
      isReadOnly,
      targetEl
    );

    this.addListeners();
  }

  addLight() {
    const hemiLight = new HemisphereLight(0xffffff, 0xffffff, 0.9);
    hemiLight.color.setHSL(0.7, 1, 0.9);
    hemiLight.position.set(0, 50, 0);
    this.scene?.add(hemiLight);
    const directional = new DirectionalLight(0xffffff, 1.0);
    directional.position.set(10, 10, 10);
    directional.lookAt(0, 0, 0);
    this.scene?.add(directional);
    const ambient = new AmbientLight(0x555555);
    this.scene?.add(ambient);
  }

  setCamera(camera: PerspectiveCamera) {
    this.camera = camera;
  }

  getCameraSettings(): CameraState | undefined {
    const camera = this?.camera; // Assuming you have a 'camera' property on your scene
    const controls = this?.controls; // Assuming you are using OrbitControls or similar

    // If no camera or controls, return undefined
    if (!camera) return undefined;
    if (!controls) return undefined;

    const position = camera?.position;
    const target = new Vector3(); // Create a vector for the target

    // If using OrbitControls, get the target from its target property
    if (controls && controls.target) {
      target.copy(controls.target);
    } else {
      // If not using OrbitControls, we assume the camera is looking at the scene's (0,0,0)
      target.set(0, 0, 0);
    }

    const res = {
      position: position.toArray(),
      target: target.toArray(),
    };

    return res;
  }

  setCameraSettings(settings: { position: number[]; target: number[] }) {
    const camera = this?.camera; // Assuming you have a 'camera' property on your scene
    const controls = this?.controls; // Assuming you are using OrbitControls or similar

    // If no camera or controls, return undefined
    if (!camera) return;
    if (!controls) return;
    if (!settings?.position || !settings?.target) return;

    // Set the camera position and target
    camera.position.fromArray(settings.position);
    controls.target.fromArray(settings.target);

    // Update the controls
    controls.update();
  }

  destroy() {
    // Clean up your scene here

    this.renderer?.dispose();
    this.camera = undefined;
    this.scene = new Scene();
    this.controls = undefined;
    this.removeListeners();
  }

  render() {
    if (!this.pointCloudCenter) {
      this.pointCloudCenter = this.findCenter();
    }
    this.updateOverlayScene();
  }

  addListeners() {
    this.eventBusLookup[EventBusNames.Point3dLocationMarkerClicked] =
      eventBus.on(
        EventBusNames.Point3dLocationMarkerClicked,
        this.updateSelectedCameraPosition,
        true
      );

    this.eventBusLookup[EventBusNames.ImageCarouselImageClicked] = eventBus.on(
      EventBusNames.ImageCarouselImageClicked,
      this.updateSelectedCameraPosition,
      true
    );
  }

  findCenter(): Vector3 {
    return new Vector3(0, 0, 0);
  }

  toggleAll3dAnnotations = (show: boolean) => {
    if (show) {
      this.annotations3d?.showAll3dAnnotations();
    } else {
      this.annotations3d?.hideAll3dAnnotations();
    }
  };

  private updateSelectedCameraPosition = ({ detail }: EventBusData) => {
    const { id } = detail;

    this.annotations3d?.highlight3dPointById(
      id.toString(),
      ColorsThreeD.Magenta
    );
  };

  private removeListeners() {
    eventBus.remove(
      EventBusNames.Point3dLocationMarkerClicked,
      this.updateSelectedCameraPosition,
      this.eventBusLookup[EventBusNames.Point3dLocationMarkerClicked]
    );
    eventBus.remove(
      EventBusNames.ImageCarouselImageClicked,
      this.updateSelectedCameraPosition,
      this.eventBusLookup[EventBusNames.ImageCarouselImageClicked]
    );
  }

  // not implemented
  pauseControls() {
    console.warn('calling unimplemented method pauseControls');
  }
  unPauseControls(): void {
    console.log('calling unimplemented method unPauseControls');
  }
  fitToScreen(): void {
    console.log('calling unimplemented method fitToScreen');
  }
}
