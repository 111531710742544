import { useAuth } from 'oidc-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@agerpoint/component';
import {
  CaptureJobTypes,
  EffectNames,
  LdFlags,
  ViewerType,
} from '@agerpoint/types';
import {
  hasPermission,
  isGaussianJob,
  isNonLidarJob,
  useActionListener,
  useGlobalStore,
  usePageTitle,
  useRecentAccess,
} from '@agerpoint/utilities';

import { ManageCaptureWrapper } from '../captures-shared/capture-details-modal/manage-capture-wrapper';
import { AllControllersComponent } from '../three-d-wrapper/all-controllers-component';
import { ThreeDAnnotationsPlugin } from '../three-d-wrapper/plugins/annotations-plugin/three-d-annotations-plugin';
import { CaptureViewerFloatingComponents } from './capture-viewer-floating-components/capture-viewer-floating-components';
import { useCapturesViewerContext } from './captures-viewer-context';
import { CapturesViewerGallery2 } from './captures-viewer-gallery2/captures-viewer-gallery2';
import { useCapturesViewerQueries } from './captures-viewer-queries';
import { CapturesViewerSidebar3 } from './captures-viewer-sidebar3/cvs3';

export const CapturesViewer2 = () => {
  const { userData } = useAuth();
  const [lowResJobId, setLowResJobId] = useState<number>();

  const { permissions } = useGlobalStore();

  const { selectedCaptureJob, viewerController, setViewerController } =
    useCapturesViewerContext();

  const {
    captureQuery,
    captureJobsWithPlyInfoQueries,
    captureJobImagesQuery,
    captureJobImagesForLowResJobIdQuery,
    capturePutMutation,
    customerAnalyticsQuery,
    analyticRequestsQuery,
  } = useCapturesViewerQueries({
    selectedCaptureJob,
    lowResJobId,
  });

  const recentAccess = useRecentAccess();
  const location = useLocation();
  const navigate = useNavigate();

  usePageTitle(
    () => captureQuery?.data?.captureName,
    [captureQuery.data?.captureName]
  );

  useEffect(() => {
    const regex = /^\/captures\/\d+\/\d+$/;
    const isEptIdDetected = regex.test(location.pathname);

    if (isEptIdDetected && captureQuery.data?.id) {
      navigate(`/captures/${captureQuery.data.id}`, {
        replace: true,
      });
    }
  }, [location, captureQuery.data]);

  useEffect(() => {
    if (
      !captureJobImagesForLowResJobIdQuery?.data ||
      viewerController?.threeController?.info?.cameraPositionsLoaded
    )
      return;

    viewerController?.threeController?.removeCameraPositions();
    viewerController?.threeController?.loadCameraPositions(
      captureJobImagesForLowResJobIdQuery?.data ?? []
    );
  }, [captureJobImagesForLowResJobIdQuery?.data, viewerController]);

  const drawCaptureImagesInPotree = useCallback(() => {
    if ((captureJobImagesQuery.data?.length ?? 0) === 0) {
      viewerController?.potreeController?.removeCameraPositions();
      return;
    }

    viewerController?.potreeController?.removeCameraPositions();
    viewerController?.potreeController?.loadCameraPositions(
      captureJobImagesQuery.data ?? [],
      hasPrismPermission
    );
  }, [viewerController, captureJobImagesQuery?.data]);

  const drawCaptureImagesInGs3d = useCallback(async () => {
    // special case for this one right now
    // we need to find the low res job and use its images

    const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
      return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
    });
    if (!lowResJob || !lowResJob?.id || !captureQuery?.data?.id) return;
    const lowResJobId = lowResJob.id;
    viewerController?.threeController?.setCaptureMetadata(captureQuery?.data);
    setLowResJobId(lowResJobId);
  }, [
    captureQuery?.data,
    captureJobImagesForLowResJobIdQuery,
    viewerController,
  ]);

  const pointCloudLoadedFn = useCallback(() => {
    if (!captureQuery.data?.id) return;

    // camera settings
    if (!selectedCaptureJob?.cameraSettings) {
      viewerController?.potreeController?.setCameraFrontView();
    } else {
      viewerController?.potreeController?.setCameraSettings();
    }

    // capture images
    // for now we are not drawing this on init because its too confusing
    if (selectedCaptureJob && isGaussianJob(selectedCaptureJob)) {
      drawCaptureImagesInGs3d();
    } else {
      drawCaptureImagesInPotree();
    }
  }, [
    selectedCaptureJob,
    viewerController,
    captureJobImagesQuery?.data,
    captureQuery?.data,
  ]);

  useActionListener(EffectNames.POTREE_POINT_CLOUD_LOADED, pointCloudLoadedFn);
  useActionListener(EffectNames.GS_3D_POINT_CLOUD_LOADED, pointCloudLoadedFn);

  useEffect(() => {
    if (!captureQuery.data?.id) return;
    recentAccess.add({
      id: `capture-${captureQuery.data.id}`,
      dataType: 'capture',
      name: captureQuery.data.captureName ?? 'Unknown',
      path: `/captures/${captureQuery.data.id}`,
    });
  }, [captureQuery.data]);

  useEffect(() => {
    if (selectedCaptureJob === undefined) {
      viewerController?.setViewerType(ViewerType.Unavailable);
      return;
    }

    if (
      selectedCaptureJob === null ||
      (!isGaussianJob(selectedCaptureJob) &&
        !selectedCaptureJob?.eptPointcloudId)
    ) {
      viewerController?.setViewerType(ViewerType.Unavailable);
      return;
    }

    if (isGaussianJob(selectedCaptureJob)) {
      viewerController?.setViewerType(ViewerType.Three);
    } else {
      viewerController?.setViewerType(ViewerType.Potree);
    }
  }, [selectedCaptureJob, viewerController?.setViewerType]);

  const captureJobWithPlyInfo = useMemo(
    () =>
      captureJobsWithPlyInfoQueries?.find(
        (q) => q.data?.id === selectedCaptureJob?.id
      )?.data,
    [captureJobsWithPlyInfoQueries, selectedCaptureJob]
  );

  useEffect(() => {
    // Only for Gaussian jobs
    if (!selectedCaptureJob) {
      return;
    }
    if (!isGaussianJob(selectedCaptureJob)) {
      return;
    }
    if (!captureJobWithPlyInfo) {
      return;
    }
    if (!captureJobWithPlyInfo.plyDownloadUrl) {
      return;
    }

    viewerController?.threeController?.setCaptureJobMetadata(
      captureJobWithPlyInfo
    );
    viewerController?.threeController?.loadPlyModel(
      captureJobWithPlyInfo.plyDownloadUrl
    );
  }, [
    selectedCaptureJob?.id,
    viewerController?.threeController?.info.viewerReady,
    captureJobWithPlyInfo?.id,
  ]);

  useEffect(() => {
    // Only for pointclouds

    if (!selectedCaptureJob) {
      return;
    }
    if (isGaussianJob(selectedCaptureJob)) {
      return;
    }
    if (!selectedCaptureJob?.eptPointcloudId) {
      return;
    }

    viewerController?.potreeController?.setCaptureJobMetadata(
      selectedCaptureJob
    );
    viewerController?.potreeController?.removePointcloud();
    viewerController?.potreeController?.loadPointcloud(
      selectedCaptureJob.eptPointcloudId
    );
  }, [
    selectedCaptureJob?.id,
    viewerController?.potreeController?.info.viewerReady,
  ]);

  const hasPrismPermission = useMemo(
    () => hasPermission(LdFlags.PotreeImagePrism, permissions),
    [permissions]
  );

  const overlay = useMemo(() => {
    if (isNonLidarJob(captureQuery.data, selectedCaptureJob ?? undefined)) {
      const hasHDModelRequested = analyticRequestsQuery.data?.some(
        (ar) => ar.customerAnalytic?.analytic?.analyticName === 'HD Model'
      );

      const hdModelAnalytic = customerAnalyticsQuery.data?.find(
        (ca) => ca.analytic?.analyticName === 'HD Model'
      );
      const canRequestHDModel = !!hdModelAnalytic;

      return (
        <div className="flex flex-col font-normal items-center">
          <div className="font-bold text-2xl">No Model Available</div>
          <div className="pt-2 pb-8 text-base">
            This capture doesn&apos;t have a model yet
          </div>
          <Button.Primary
            id="trigger-high-res-job"
            onClick={() => {
              if (hasHDModelRequested) return;
              navigate(`analytics/${hdModelAnalytic?.analytic?.id}`);
            }}
            label={
              hasHDModelRequested
                ? 'HD Model Requested'
                : canRequestHDModel
                ? 'Request HD Model'
                : 'HD Model Unavailable'
            }
            disabled={hasHDModelRequested || !canRequestHDModel}
          />
        </div>
      );
    }

    return undefined;
  }, [
    selectedCaptureJob,
    captureQuery.data,
    analyticRequestsQuery.data,
    customerAnalyticsQuery.data,
  ]);

  return (
    <div className="w-full h-full">
      <AllControllersComponent
        controller={setViewerController}
        potree={{
          showTools: true,
          showCloudTools: false,
        }}
        three={{
          showTools: true,
          showLoadingIndicator: true,
        }}
        plugins={[
          <ThreeDAnnotationsPlugin key={`three-d-annotations-plugin`} />,
        ]}
        overlay={overlay}
        token={userData?.access_token}
      />
      <CapturesViewerSidebar3 />
      <CapturesViewerGallery2 />

      <ManageCaptureWrapper
        archiveCapture={() => {
          //
          capturePutMutation.mutate({
            id: captureQuery.data?.id as number,
            data: { ...captureQuery.data, archived: true },
          });
        }}
        unarchiveCapture={() => {
          //
          capturePutMutation.mutate({
            id: captureQuery.data?.id as number,
            data: { ...captureQuery.data, archived: false },
          });
        }}
      />
      <CaptureViewerFloatingComponents />
    </div>
  );
};
